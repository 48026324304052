<template>
  <div >
    <div class="content-home">
      <div class="page-wrapper">
        <HomeMenu></HomeMenu>
        <NavMobileHome></NavMobileHome>
        <v-main>
          <router-view/>
        </v-main>
        <HomeFooter></HomeFooter>
      </div>
    </div>
  </div>
</template>

<script>
    import HomeMenu from "./HomeMenu";
    import HomeFooter from "./HomeFooter";
    import NavMobileHome from "./modules/NavMobileHome";
    export default {
        data: function() {
            return {

            }
        },
        name: 'Home',
        methods:{

        },
        created(){
        },
        destroyed () {

        },
        computed: {

        },
        components: {
            NavMobileHome,
          HomeFooter,
          HomeMenu
        }
    }


</script>

<style scoped>

</style>