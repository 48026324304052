<template>
    <div class="home-page">
        <div class="header">
            <div class="container">
                <div class="left">
                    <p>{{__('welcome to')}}</p>
                    <h1>Babylon</h1>
                    <span>{{__('Home OF THE BEST FOOD')}}</span>
                    <router-link class="btn-site" :to="{name: 'MenuPage'}">{{__('View menu')}}</router-link>
                </div>
            </div>
        </div>
        <div class="serves">
            <div class="container">
                <ul>
                    <li>
                        <img src="/dist/images/header-img-1.svg" alt="">
                        <p>{{__('Panoramic view')}}</p>
                        <span>{{__('Panoramic Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis.')}}</span>
                    </li>
                    <li>
                        <img src="/dist/images/header-img-2.svg" alt="">
                        <p>{{__('Own parking')}}г</p>
                        <span>{{__('parking Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis.')}}</span>
                    </li>
                    <li>
                        <img src="/dist/images/header-img-3.svg" alt="">
                        <p>{{__('FREE WIFI')}}</p>
                        <span>{{__('WIFI Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis.')}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="discover">
            <div class="container">
               <div class="left">
                   <div class="top">
                       <h2>{{__('Discover')}} <span>{{__('Our Story')}}</span></h2>
                       <p>{{__('Babylon Restaurant, where a modern, elegant and sophisticated ambiance comes together with an impressive city view, is one of the indispensable classics of Antalya. With its panoramic sea view and warm atmosphere, it is always the address for those who want to have an unforgettable experience.')}}</p>
                   </div>
                   <ul>
                       <li>
                           <p>{{__('BABYLON ANTALYA')}}</p>
                       </li>
                   </ul>
               </div>
               <div class="right">
                   <img src="/dist/images/discover-img.png" alt="">
               </div>
            </div>
        </div>
        <div class="offer">
            <div class="container">
                <div>
                    <p>{{__('Celebrate at one of NY’s most awarded restaurant.')}}</p>
                    <span>{{__('Only this month,business lunch from $25')}}</span>
                </div>
                <router-link class="btn-site" :to="{name: 'home'}">{{__('GET OFFER')}}</router-link>
            </div>
        </div>
        <div class="menu-home top-menu" >
            <div class="container" v-if="menu">
                <h2>{{__('TOP')}} <span>{{__('Offers')}}</span></h2>
                <div class="menu">
                    <div class="container">
<!--                        <NavMobile></NavMobile>-->
                        <div class="top">
                          <a v-on:click="category= key" class="btn-site" :class="(key== category) ? 'active' : ''" v-for="(item, key) in menu" :key="key">{{__(key)}}</a>
                        </div>
                        <ul>
                            <MenuItem v-for="(item, key) in menu[category]['items']" v-bind:weight="menu[category]['weight']" :key="key" v-bind:item="item"/>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else>

            </div>
        </div>
<!--        <div class="party">-->
<!--            <div class="container">-->
<!--                <h2>Our Party</h2>-->
<!--                <ul>-->
<!--                    <li>-->
<!--                        <div class="date">-->
<!--                            <p>12.12.2022 19:00 </p>-->
<!--                        </div>-->
<!--                        <div class="body">-->
<!--                            <p class="name">Spicy Club</p>-->
<!--                            <img src="/dist/images/party.png" alt="">-->
<!--                            <p class="desription">Lorem ipsum lorem ipsum lorem ipsum lorem</p>-->
<!--                            <a href="#" class="btn-site">Купить билет</a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div class="date">-->
<!--                            <p>12.12.2022 19:00 </p>-->
<!--                        </div>-->
<!--                        <div class="body">-->
<!--                            <p class="name">Spicy Club</p>-->
<!--                            <img src="/dist/images/party.png" alt="">-->
<!--                            <p class="desription">Lorem ipsum lorem ipsum lorem ipsum lorem</p>-->
<!--                            <a href="#" class="btn-site">Купить билет</a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div class="date">-->
<!--                            <p>12.12.2022 19:00 </p>-->
<!--                        </div>-->
<!--                        <div class="body">-->
<!--                            <p class="name">Spicy Club</p>-->
<!--                            <img src="/dist/images/party.png" alt="">-->
<!--                            <p class="desription">Lorem ipsum lorem ipsum lorem ipsum lorem</p>-->
<!--                            <a href="#" class="btn-site">Купить билет</a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </div>-->
        <div class="gallery">
            <div class="container">
                <h2>{{__('Our Food')}} <span>{{__('Gallery')}}</span></h2>
                <div class="gallery-img">
                  <img class="img" :src="img" v-for="(img, key) in imgs" :key="key"  @click="showMultiple(key)">
                </div>
                <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        :index="index"
                        @hide="handleHide"
                ></vue-easy-lightbox>
            </div>
        </div>
        <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d204208.5514341489!2d30.59799143547954!3d36.896122448381654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe0e48095cedfc83d!2sBabylon%20Antalya!5e0!3m2!1str!2str!4v1630055793716!5m2!1str!2str" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</template>

<script>
    import MenuItem from "./Menu/MenuItem";
    // import NavMobile from "../modules/NavMobile";

export default {
  name: 'home',
    data: function() {
      return {
          imgs: ['/dist/images/gallerey/img-1.png',
                  '/dist/images/gallerey/img-3.png',
                  '/dist/images/gallerey/img-4.png',
                    '/dist/images/gallerey/img-7.png',
                    '/dist/images/gallerey/img-2.png',
                  '/dist/images/gallerey/img-5.png',
                  '/dist/images/gallerey/img-6.png',
                    '/dist/images/gallerey/img-9.png',
                ],  // Img Url , string or Array
          visible: false,
          index: 0,
          menu: null,
          home: null,
          category: null
      }
  },
  watch: {

  },
    created() {
        this.$http.get('ajax/home')
            .then(response=> {
                return response.json()
            })
            .then((response)=> {
                let menu= response.data.menu;
                this.menu= menu;
                this.category= Object.keys(menu)[0];
            }).catch(err => {
            this.httpHandler(err);
        });

    },
  methods:{
      showMultiple(index) {
          this.index= index;
          this.visible = true;
      },
      handleHide() {
          this.visible = false
      }
  },
  computed: {

  },
  mounted() {

  },
  components: {
      // NavMobile,
      MenuItem
  }
}
</script>
