<template>
    <div class="menu-page top-menu">
        <div class="header">
            <h1>{{__('OUR')}} <span>{{__('MENU')}}</span></h1>
        </div>
        <div class="menu" v-if="menu">
            <div class="container">
                <div class="top">
                   <a v-on:click="category= key" class="btn-site" :class="(key== category) ? 'active' : ''" v-for="(item, key) in menu" :key="key">{{__(key)}}</a>
                </div>
                <ul>
                    <MenuItem v-for="(item, key) in menu[category]['items']" :weight="menu[category]['weight']" :key="key" v-bind:item="item"/>
                </ul>
            </div>
        </div>
        <div v-else>

        </div>
    </div>
</template>

<script>
import MenuItem from "./Menu/MenuItem";
export default {
  name: 'MenuPage',
    data: function() {
      return {
          menu: null,
          category: null
      }
  },
  watch: {

  },
  created() {

      this.$http.get('ajax/menu')
          .then(response=> {
              return response.json()
          })
          .then((response)=> {
              let menu= response.data.menu;
              this.menu= menu;
              this.category= Object.keys(menu)[0];
          }).catch(err => {
              this.httpHandler(err);
          });
  },
  methods:{

  },
  computed: {

  },
  mounted() {

  },
  components: {
      MenuItem

  }
}
</script>
