<template>
    <div class="footer-main">
        <div class="container">
            <div class="logo">
                <router-link :to="{name: 'home'}"><img src="/dist/images/logo.svg" alt=""></router-link>
            </div>
            <div class="center">
                <span>{{__('© 2022 Babylon. All Rights Reserved.')}}</span>
            </div>
            <div class="social">
                <a href="https://www.instagram.com/babylonantalya/?hl=tr" target="_blank">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5862 0H5.41382C2.42859 0 0 2.42859 0 5.41382V14.5863C0 17.5714 2.42859 20 5.41382 20H14.5863C17.5714 20 20 17.5714 20 14.5863V5.41382C20 2.42859 17.5714 0 14.5862 0V0ZM18.8275 14.5863C18.8275 16.9249 16.9249 18.8275 14.5862 18.8275H5.41382C3.0751 18.8275 1.17249 16.9249 1.17249 14.5863V5.41382C1.17249 3.0751 3.0751 1.17249 5.41382 1.17249H14.5863C16.9249 1.17249 18.8275 3.0751 18.8275 5.41382V14.5863Z" />
                        <path d="M9.99985 4.53125C6.98441 4.53125 4.53125 6.98441 4.53125 9.99985C4.53125 13.0153 6.98441 15.4684 9.99985 15.4684C13.0153 15.4684 15.4684 13.0153 15.4684 9.99985C15.4684 6.98441 13.0153 4.53125 9.99985 4.53125ZM9.99985 14.296C7.63107 14.296 5.70374 12.3688 5.70374 9.99985C5.70374 7.63107 7.63107 5.70374 9.99985 5.70374C12.3688 5.70374 14.296 7.63107 14.296 9.99985C14.296 12.3688 12.3688 14.296 9.99985 14.296Z"/>
                        <path d="M15.5993 2.58936C14.7082 2.58936 13.9834 3.3143 13.9834 4.20526C13.9834 5.09637 14.7082 5.82132 15.5993 5.82132C16.4904 5.82132 17.2154 5.09637 17.2154 4.20526C17.2154 3.31415 16.4904 2.58936 15.5993 2.58936ZM15.5993 4.64868C15.3549 4.64868 15.1559 4.44971 15.1559 4.20526C15.1559 3.96066 15.3549 3.76184 15.5993 3.76184C15.8439 3.76184 16.0429 3.96066 16.0429 4.20526C16.0429 4.44971 15.8439 4.64868 15.5993 4.64868Z"/>
                    </svg>
<!--                    <span>Instagram</span>-->
                </a>
                <a href="https://www.facebook.com/Babylon-Antalya-102365388796016" target="_blank">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_122:39535)">
                            <path d="M11.3501 19.9609H8.14106C7.60504 19.9609 7.16901 19.5258 7.16901 18.9908V11.7667H5.29676C4.76074 11.7667 4.32471 11.3313 4.32471 10.7965V7.70096C4.32471 7.16599 4.76074 6.7308 5.29676 6.7308H7.16901V5.18066C7.16901 3.64365 7.65259 2.33597 8.56731 1.39923C9.48616 0.458221 10.7702 -0.0390625 12.2808 -0.0390625L14.7282 -0.0350952C15.2633 -0.0341797 15.6985 0.401001 15.6985 0.935059V3.8092C15.6985 4.34418 15.2627 4.77936 14.7268 4.77936L13.079 4.77997C12.5765 4.77997 12.4485 4.88052 12.4211 4.91135C12.376 4.96246 12.3224 5.10696 12.3224 5.50598V6.73065H14.603C14.7747 6.73065 14.941 6.77292 15.0839 6.85257C15.3923 7.02454 15.584 7.3497 15.584 7.70111L15.5828 10.7967C15.5828 11.3313 15.1468 11.7665 14.6108 11.7665H12.3224V18.9908C12.3224 19.5258 11.8862 19.9609 11.3501 19.9609ZM8.34379 18.7885H11.1474V11.2418C11.1474 10.8846 11.4387 10.594 11.7964 10.594H14.408L14.4091 7.90329H11.7963C11.4385 7.90329 11.1474 7.61276 11.1474 7.25555V5.50598C11.1474 5.04791 11.1941 4.52698 11.5405 4.13544C11.9591 3.66211 12.6188 3.60748 13.0787 3.60748L14.5238 3.60687V1.13709L12.2799 1.13342C9.85232 1.13342 8.34379 2.68433 8.34379 5.18066V7.25555C8.34379 7.61261 8.05269 7.90329 7.69494 7.90329H5.49949V10.594H7.69494C8.05269 10.594 8.34379 10.8846 8.34379 11.2418V18.7885ZM14.7259 1.13739H14.726H14.7259Z"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_122:39535">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
<!--                    <span>Facebook</span>-->
                </a>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'HomeFooter',
        data: function() {
            return {

            }
        },

        computed: {

        },
        created() {

        },
        components: {
        }
    }


</script>

<style scoped>

</style>