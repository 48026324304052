<template>
    <div class="covid-page menu-page">
        <div class="header">
            <h1>{{__('covid')}} <span>{{__('measures')}}</span></h1>
        </div>
        <div class="container">
            <img class="img" src="/dist/images/covid.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {
  name: 'ShopPage',
    data: function() {
      return {

      }
  },
  watch: {

  },
  created() {

  },
  methods:{

  },
  computed: {

  },
  mounted() {

  },
  components: {

  }
}
</script>
