<template>
  <li>
    <div class="left">
      <img :src="item.image" v-if="item.image" alt="">
      <div  v-else></div>
    </div>
    <div class="right">
      <div>
        <p class="name">{{getMenu.name}}</p>
        <p class="compound" v-if="getMenu.short_description">{{getMenu.short_description}}</p>
      </div>
      <p class="description" v-html="getMenu.description" v-if="getMenu.description"></p>
      <p class="price">
        <span class="price-gram" v-if="item.weight > 0">
          {{(lang=='tr' && weight== 2) ? item.weight/10 : item.weight }}
          <span v-if="weight== 1">{{__('gram')}}</span>
          <span v-else-if="weight== 2">{{__('ml')}}</span>
          <span v-else-if="weight== 3">{{__('pcs')}}</span>
        </span>
        <span>{{item.price}} {{__('TL')}}</span></p>
    </div>
  </li>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MenuItem',
    data: function() {
      return {
          menu: null,
          category: null
      }
  },
  watch: {

  },
  props: {
    item: {
        type: Object
    },
    weight: {
        type: [
            String, Number
        ]
    }
  },
  created() {

  },
  methods:{

  },
  computed: {
      lang() {
          return this.$store.state.language.lang;
      },
      getMenu() {
          let item= this.item;
          let index= _.findIndex(item.translate_full, {lang: this.lang});
          console.log('test');
          return (index >= 0) ? item.translate_full[index] : item.translate_full[0];
      }
  },
  mounted() {

  },
  components: {

  }
}
</script>
