export default {
    state: {
        lang: (localStorage.language) ? localStorage.language : 'tr',
        list: null
    },
    getters: {

    },
    actions: {
        asyncLanguage(context, payload) {
            context.commit('updateLanguage', payload);
        },
        asyncLanguages(context, payload) {
            context.commit('updateLanguages', payload.data);
        },
    },
    mutations: {
        updateLanguage(state, language) {
            this.state.language.lang= language;
        },
        updateLanguages(state, data) {
            this.state.language.list= (data.length) ? data : null;
        }
    }
}
