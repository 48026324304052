<template>
    <div class="pages-page">



    </div>
</template>

<script>
export default {
  name: 'PagesPage',
    data: function() {
      return {

      }
  },
  watch: {

  },
  created() {

  },
  methods:{

  },
  computed: {

  },
  mounted() {

  },
  components: {

  }
}
</script>
