<template>
    <div class="home-menu-mob">
            <nav class="overlayMenu"
                 ref="overlay"
            >
                <div class="logo" v-on:click="navToggle">
                    <router-link :to="{name: 'home'}">
                        <img src="/dist/images/logo.svg" alt="">
                    </router-link>
                </div>
                <div class="menu-right">
                    <div class="body " ref="navToggle" v-on:click="navToggle">
                        <div class="menu">
                            <router-link :to="{name: 'home'}">{{__('Home')}}</router-link >
                            <router-link :to="{name: 'MenuPage'}">{{__('Menu')}}</router-link>
<!--                            <router-link :to="{name: 'PagesPage'}">Pages</router-link>-->
                            <router-link :to="{name: 'ShopPage'}">{{__('Covid measures')}}</router-link>
                        </div>
                        <div class="social">

                        </div>
                    </div>
                </div>
<!--                <div class="mob-leng">-->
<!--                    <DropdownLanguages></DropdownLanguages>-->
<!--                </div>-->

            </nav>

        <div class="navBurger" role="navigation" ref="navToggle" v-on:click="navToggle"></div>
    </div>
</template>

<script>
    // import DropdownLanguages from "./DropdownLanguages";
    export default {
        name: 'NavMobileHome',
        data: function() {
            return {
            }
        },
        props: {

        },
        methods: {
            navToggle: function () {
                this.$refs.navToggle.classList.toggle('active');
                this.$refs.overlay.classList.toggle('open');
            }
        },
        components: {
            // DropdownLanguages
        }
    }
</script>
<style scoped>
    .social svg {
        fill: #FF0000;
        transition: 200ms;
    }
    .social svg{
        margin: 0px 12px;
    }
    .social{
        list-style: none;
        padding-left: 0;
        margin-top: 55px;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        /*padding-bottom: 86px;*/
    }
    .social li{
        display: inline-block;
        margin-right: 15px;
        margin-left: 15px;
    }
     .social li a{
        display: block;
        width: 30px;
        height: 30px;
        background: rgba(102, 102, 102, 0.5);
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .social li a svg{
        fill: #FF0000;
        width: 16px;
        height: 16px;
    }
    .social li a:hover{
        background: #ffffff;
        text-decoration: none;

    }

    .overlayMenu .body{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 20px;
    }
    .overlayMenu .body a{
        margin-top: 20px;
        color: #ffffff;
    }
    .overlayMenu .body a.router-link-exact-active{
        color: #FEDC43;
    }
    .overlayMenu .body a svg{
        fill: #FF0000;
    }
    .overlayMenu .logo{
        text-align: center;
        margin-top: 20px;
    }
    .overlayMenu .logo a{

        padding: 0;
        position: relative;
    }
    .overlayMenu .logo a img{
        width: 140px;
    }

    .home-menu-mob{
        display: none;
    }
    .navBurger {
        display: none;
        position: fixed;
        left: 22px;
        top: 35px;
        cursor: pointer;
        background: #ffffff;
        box-sizing: border-box;
        background-clip: content-box;
        width: 20px;
        padding-left: 0px;
        padding-right: 0px;
        height: 15px;
        border-top: 6px solid transparent;
        border-bottom: 7px solid transparent;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        z-index: 111;
    }
    .navBurger:before, .navBurger:after {
        content: "";
        position: absolute;
        background: #ffffff;
        height: 2px;
        width: 20px;
        left: 0px;
        will-change: rotate;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .navBurger:before {
        top: -6px;
    }
    .navBurger:after {
        bottom: -6px;
    }
    .navBurger.active {
        margin-top: 0px;
        left: 22px;
        position: fixed;
        z-index: 1111;
        background: transparent;
        background-clip: content-box;
    }
    .navBurger.active:before {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 0px;
        background: #ffffff;
        width: 100%;
    }
    .navBurger.active:after {
        bottom: 0px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #ffffff;
        width: 100%;
    }
    .overlayMenu {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform-origin: left top;
        transform: scale(0);
        visibility: hidden;
        transition: all 0.4s ease-in-out;
        z-index: 1111;
        background: #2C2C2C;
    }
    .overlayMenu.open {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        z-index: 1111;
        position: fixed;
    }
    .overlayMenu .menu{
        margin-top: 10px;
        text-align: center;
    }
    .overlayMenu .menu a{
        display: block;
        color: #ffffff;
        font-size: 14px;
        -webkit-transition: 300ms;
        transition: 300ms;
    }
    .overlayMenu .menu-right a.router-link-exact-active{
        color: #D67E34;
    }
    .overlayMenu .menu-right a:hover{
        color: #D67E34;
    }
    .overlayMenu .menu-right .v-btn--active:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -6px;
        background-color: #3C55CF;
        display: block;
    }

    .overlayMenu .menu .v-btn--active{
        color: #3C55CF;
    }


    @media only screen and (max-width : 900px){
        .navbar.is-visible .navBurger.active {
            margin-top: 0px;
            left: 25px;
            position: fixed;
            z-index: 1111;
            background: transparent;
            background-clip: content-box;
        }
        .home-menu-mob{
            display: block;
            /*position: fixed;*/
            /*top: 0px;*/
            /*width: 100%;*/
            /*left: 0;*/
            /*z-index: 2;*/
            /*background-color: #2C2C2C;*/
            /*height: 100vh;*/
        }
        .navBurger{
            display: block;
        }
        .navbar.is-visible .navBurger{
            position: fixed;
            left: 25px;
            top: 30px;
            cursor: pointer;
            background: #2a2d38;
            box-sizing: border-box;
            background-clip: content-box;
            width: 20px;
            padding-left: 0px;
            padding-right: 0px;
            height: 15px;
            border-top: 6px solid transparent;
            border-bottom: 7px solid transparent;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
            z-index: 1111;
        }
        .navbar.is-visible  .navBurger:before, .navbar.is-visible  .navBurger:after {
            background: #2a2d38;
        }
    }
    @media only screen and (max-width : 500px){
        .navBurger{
            top: 32px;
        }
    }
    @media only screen and (max-width : 380px){
        .navBurger {
            top: 28px;
        }
    }
</style>
