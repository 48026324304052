<template>
    <div class="home-menu">
            <div class="container">
                <div class="left">
                    <div class="logo">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo.svg" alt=""></router-link>
                    </div>
                </div>
                <div class="right">
                    <div class="menu">
                        <router-link :to="{name: 'home'}">{{__('Home')}}</router-link >
                        <router-link :to="{name: 'MenuPage'}">{{__('Menu')}}</router-link>
<!--                        <router-link :to="{name: 'PagesPage'}">Pages</router-link>-->
                        <router-link :to="{name: 'ShopPage'}">{{__('Covid measures')}}</router-link>
                    </div>
                    <div class="contact-menu">
                        <div class="phone">
                            <div>
                                <a href="tel:+90 535 761 06 71">
                                    <img src="/dist/images/phone.svg" alt="">
                                    <span>{{__('+90 535 761 06 71')}}</span>
                                </a>
                            </div>
                            <p>{{__('Contact us for reservation')}}</p>
                        </div>
                        <DropdownLanguages></DropdownLanguages>
                        <a href="tel:+90 535 761 06 71" class="btn-site">{{__('BOOK A TABLE')}}</a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import DropdownLanguages from "./modules/DropdownLanguages";
    export default {
        name: 'HomeMenu',
        data: function() {
            return {

            }
        },
        methods:{
            handleSCroll () {
                let header = document.querySelector(".home-menu");
                if (window.scrollY > 70) {
                    header.classList.add("is-visible");
                }
                else {
                    header.classList.remove("is-visible");
                }
            }
        },
        computed: {

        },
        created () {
            window.addEventListener('scroll', this.handleSCroll);

        },
        destroyed () {
            window.removeEventListener('scroll', this.handleSCroll);

        },
        components: {
            DropdownLanguages

        }
    }


</script>

<style scoped>

</style>